<template>
  <component :is="'div'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="orderData === undefined">
      <h4 class="alert-heading">Fehler bei der Abfrage der Daten</h4>
      <div class="alert-body">
        Es wurde kein Auftrag mit dieser ID gefunden. Gehen Sie bitte zurück zum
        <b-link class="alert-link" :to="{ name: 'home' }"> Dashboard </b-link>
        und wählen Sie einen anderen Auftrag aus.
      </div>
    </b-alert>
    <b-card
      v-if="orderData.Auftragsnummer"
      :header="`Details zu Auftrag #${orderData.Auftragsnummer || '-'}`"
      header-tag="h1"
      header-bg-variant="white"
      header-class="border-bottom"
    >
      <b-row class="mt-2">
        <b-col cols="12" md="6">
          <h5 class="mb-50">Auftraggeber</h5>
          <p class="ml-1 mb-50"><b>Firma:</b> {{ orderData.Kunde.Firma }}</p>
          <p class="ml-1">
            <b>Auftragsort:</b>
            {{
              orderData.Abweichender_Aufragsort
                ? `${orderData.Abweichender_Aufragsort.Strasse} ${orderData.Abweichender_Aufragsort.Hausnummer}, ${orderData.Abweichender_Aufragsort.PLZ} ${orderData.Abweichender_Aufragsort.Stadt}`
                : `${orderData.Kunde.Anschrift.Strasse} ${orderData.Kunde.Anschrift.Hausnummer}, ${orderData.Kunde.Anschrift.PLZ} ${orderData.Kunde.Anschrift.Stadt}`
            }}
          </p>

          <h5 class="mb-50 mt-2">Ansprechpartner</h5>
          <p class="ml-1 mb-50">
            <b>Name:</b> {{ ansprechpartner.Vorname }}
            {{ ansprechpartner.Nachname }}
          </p>
          <p class="ml-1 mb-50">
            <b>Abteilung:</b> {{ ansprechpartner.Abteilung }}
          </p>
          <p class="ml-1 mb-50">
            <b>Position:</b> {{ ansprechpartner.Position }}
          </p>
          <p class="ml-1 mb-50"><b>Email:</b> {{ ansprechpartner.Email }}</p>
          <p class="ml-1 mb-50">
            <b>Telefon:</b> {{ ansprechpartner.Telefon }}
          </p>
          <p class="ml-1"><b>Mobil:</b> {{ ansprechpartner.Mobil }}</p>

          <h5 class="mb-50 mt-2">Weitere Auftragsdaten</h5>
          <p v-if="orderData.Auftrag_Bei_Pruefanweisung" class="ml-1 mb-50">
            <b>Auftrag liegt bei:</b> Prüfanweisung
          </p>
          <p v-if="orderData.Auftrag_Bei_Muster" class="ml-1 mb-50">
            <b>Auftrag liegt bei:</b> Muster
          </p>
          <p v-if="orderData.Auftrag_Bei_Sonstige" class="ml-1 mb-0">
            <b>Auftrag liegt bei:</b> {{ orderData.Auftrag_Bei_Sonstige }}
          </p>
        </b-col>
        <b-col cols="12" md="6" v-if="orderData.Abweichender_Aufragsort">
          <iframe
            class="w-100 h-100 google-map"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            :src="`https://maps.google.com/maps?&amp;q=${encodeURIComponent(
              `${orderData.Abweichender_Aufragsort.Strasse} ${orderData.Abweichender_Aufragsort.Hausnummer}, ${orderData.Abweichender_Aufragsort.PLZ} ${orderData.Abweichender_Aufragsort.Stadt}`
            )}&amp;output=embed`"
          />
        </b-col>
        <b-col cols="12" md="6" v-else-if="orderData.Kunde">
          <iframe
            class="w-100 h-100 google-map"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            :src="`https://maps.google.com/maps?&amp;q=${encodeURIComponent(
              `${orderData.Kunde.Anschrift.Strasse} ${orderData.Kunde.Anschrift.Hausnummer}, ${orderData.Kunde.Anschrift.PLZ} ${orderData.Kunde.Anschrift.Stadt}`
            )}&amp;output=embed`"
          />
        </b-col>
      </b-row>
    </b-card>

    <div v-if="orderData.Pruefpositionen">
      <b-card
        v-for="(pruefposition, pruefpositionIndex) in orderData.Pruefpositionen"
        :key="pruefposition.id"
        :header-class="{
          'border-bottom': activePruefposition === pruefposition.id,
        }"
        body-class="pb-0"
        class="position-relative"
      >
        <template #header>
          <h2 class="card-header pl-0">
            Prüfposition {{ pruefpositionIndex + 1 }} -
            {{ pruefposition.Bezeichnung }}
            <b-badge
              class="ml-1"
              :variant="status[1][pruefpositionStatus(pruefposition.Status)]"
            >
              {{ status[0][pruefpositionStatus(pruefposition.Status)] }}
            </b-badge>
          </h2>
        </template>
        <b-button
          variant="primary"
          class="toggle-pruefposition position-absolute"
          @click="setActivePruefposition(pruefposition.id)"
          >{{
            $can('read', 'Admin')
              ? 'Prüfposition anschauen'
              : 'Prüfposition bearbeiten'
          }}</b-button
        >
        <b-row
          v-if="activePruefposition === pruefposition.id"
          class="justify-content-between"
        >
          <b-col cols="12" md="6" class="py-1 border-right">
            <h5 class="mb-50">Allgemeine Informationen</h5>
            <p v-if="pruefposition.Bauteilgruppe" class="ml-1 mb-50">
              <b>Bauteilgruppe:</b> {{ pruefposition.Bauteilgruppe }}
            </p>
            <p
              v-if="pruefposition.Stueckzahl"
              class="ml-1 mb-50"
              :class="{
                'text-success': partDifferences[pruefposition.id].icon,
              }"
            >
              <b>Stückzahl:</b> {{ partDifferences[pruefposition.id].text }}
              <feather-icon
                v-if="partDifferences[pruefposition.id].icon"
                icon="CheckIcon"
                size="24"
                class="text-success"
              />
            </p>
            <p v-if="!pruefposition.Stueckzahl" class="ml-1 mb-50">
              <b>Stückzahl:</b> {{ partDifferences[pruefposition.id].text }}
            </p>
            <p v-if="pruefposition.Artikelnummer" class="ml-1 mb-50">
              <b>Artikelnummer:</b> {{ pruefposition.Artikelnummer }}
            </p>
            <p v-if="pruefposition.Chargennummer" class="ml-1">
              <b>Chargennummer:</b> {{ pruefposition.Chargennummer }}
            </p>
            <h5 class="mb-50 mt-2">Tätigkeit</h5>
            <p
              v-if="pruefposition.Taetigkeit.Kontrolle_Pruefen"
              class="ml-1 mb-50"
            >
              <b>Kontrolle/Prüfen: </b>
              <b-form-checkbox
                class="d-inline-block"
                :checked="pruefposition.Taetigkeit.Kontrolle_Pruefen"
                disabled
              />
            </p>
            <p v-if="pruefposition.Taetigkeit.Nacharbeit" class="ml-1 mb-50">
              <b>Nacharbeit: </b>
              <b-form-checkbox
                class="d-inline-block"
                :checked="pruefposition.Taetigkeit.Nacharbeit"
                disabled
              />
            </p>
            <p v-if="pruefposition.Taetigkeit.Stichprobe" class="ml-1 mb-50">
              <b>Stichprobe: </b>
              <b-form-checkbox
                class="d-inline-block"
                :checked="pruefposition.Taetigkeit.Stichprobe"
                disabled
              />
            </p>
            <p
              v-if="pruefposition.Taetigkeit.Umpacken_Komplettieren"
              class="ml-1 mb-50"
            >
              <b>Umpacken/Komplettieren: </b>
              <b-form-checkbox
                class="d-inline-block"
                :checked="pruefposition.Taetigkeit.Umpacken_Komplettieren"
                disabled
              />
            </p>
            <p
              v-if="pruefposition.Taetigkeit.Kontrolle_mit_Nacharbeit"
              class="ml-1 mb-50"
            >
              <b>Kontrolle mit Nacharbeit: </b>
              <b-form-checkbox
                class="d-inline-block"
                :checked="pruefposition.Taetigkeit.Kontrolle_mit_Nacharbeit"
                disabled
              />
            </p>
            <p v-if="pruefposition.Taetigkeit.Sonstiges" class="ml-1 mb-50">
              <b>Sonstige Tätigkeiten:</b>
              {{ pruefposition.Taetigkeit.Sonstiges }}
            </p>

            <h5 class="mb-50 mt-2">Arbeitsanweisung</h5>
            <p v-if="pruefposition.Reklamationsgrund" class="ml-1 mb-50">
              <b>Reklamationsgrund:</b> {{ pruefposition.Reklamationsgrund }}
            </p>
            <p
              v-if="pruefposition.Auszufuehrende_Taetigkeit"
              class="ml-1 mb-50"
            >
              <b>Auszufuehrende Tätigkeit:</b>
              {{ pruefposition.Auszufuehrende_Taetigkeit }}
            </p>
            <p v-if="pruefposition.Folgelieferung_Pruefen" class="ml-1 mb-50">
              <b>Folgelieferung prüfen: </b>
              <b-form-checkbox
                class="d-inline-block"
                :checked="pruefposition.Folgelieferung_Pruefen"
                disabled
              />
            </p>

            <h5 v-if="pruefposition.Anhaenge" class="mb-50 mt-2">
              Detailbilder
            </h5>
            <b-row v-if="pruefposition.Anhaenge">
              <b-col
                cols="2"
                v-for="(img, imgIndex) in pruefpositionenLightbox[
                  pruefpositionIndex
                ].imgThumbnailUrls"
                :key="imgIndex"
                @click="showLightbox(pruefpositionIndex, imgIndex)"
              >
                <b-aspect
                  aspect="1:1"
                  class="d-flex align-items-center bg-light p-1 mb-2"
                >
                  <b-img-lazy fluid class="detail-image" :src="`${img}`" />
                </b-aspect>
              </b-col>
              <vue-easy-lightbox
                escDisabled
                moveDisabled
                :visible="
                  pruefpositionenLightbox[pruefpositionIndex].lightboxVisible
                "
                :imgs="pruefpositionenLightbox[pruefpositionIndex].imgUrls"
                :index="
                  pruefpositionenLightbox[pruefpositionIndex].lightboxIndex
                "
                @hide="hideLightbox(pruefpositionIndex)"
              />
            </b-row>

            <h5
              v-if="employeeServices[pruefposition.id].length > 0"
              class="mb-50 mt-2"
            >
              Abgeschlossene Leistungen
            </h5>
            <b-card
              v-for="leistung in employeeServices[pruefposition.id]"
              :key="leistung.id"
              class="details-card single-leistung mb-1 cursor-pointer"
              @click="setActiveLeistung(leistung.id, $event)"
            >
              <b-row>
                <b-col cols="12" md="4">
                  <p class="mb-0">
                    <b>Datum:</b>
                    {{
                      new Date(leistung.Arbeitsbeginn).toLocaleDateString(
                        'de-DE',
                        {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        }
                      )
                    }}
                  </p>
                </b-col>
                <b-col cols="12" md="4">
                  <p class="mb-0">
                    <b>Geprüfte Teile:</b> {{ leistung.Teile_Gesamt }}
                  </p>
                </b-col>
                <b-col cols="12" md="4" v-if="leistung.Arbeitszeit">
                  <p class="mb-0">
                    <b>Arbeitszeit:</b>
                    {{ leistung.Arbeitszeit.substr(0, 5) }} Stunden
                  </p>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  class="px-0 mt-1 pt-1 border-top"
                  v-if="
                    activeLeistung === leistung.id &&
                    activePruefposition === pruefposition.id
                  "
                >
                  <b-row class="mx-0">
                    <b-col cols="12" md="4">
                      <p class="mb-0">
                        <b>Beginn:</b>
                        {{
                          new Date(leistung.Arbeitsbeginn).toLocaleDateString(
                            'de-DE',
                            {
                              hour: '2-digit',
                              minute: '2-digit',
                            }
                          )
                        }}
                      </p>
                    </b-col>
                    <b-col cols="12" md="4">
                      <p class="mb-0">
                        <b>Pause:</b>
                        {{ leistung.Pause.substr(3, 2) }} Minuten
                      </p>
                    </b-col>
                    <b-col cols="12" md="4">
                      <p class="mb-0">
                        <b>Ende:</b>
                        {{
                          new Date(leistung.Arbeitsende).toLocaleDateString(
                            'de-DE',
                            {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                            }
                          )
                        }}
                      </p>
                    </b-col>
                    <b-col
                      v-if="leistung.Feiertag"
                      cols="12"
                      md="12"
                      class="mt-1"
                    >
                      <p class="mb-0">
                        <b>Feiertag: </b>
                        <b-form-checkbox
                          class="d-inline-block"
                          :checked="leistung.Feiertag"
                          disabled
                        />
                      </p>
                    </b-col>
                    <b-col cols="12" md="12" class="mt-1">
                      <p class="mb-0">
                        <b>Tätigkeit ausgeführt: </b>
                        <b-form-checkbox
                          class="d-inline-block"
                          :checked="leistung.Taetigkeit_Ausgefuehrt"
                          disabled
                        />
                      </p>
                    </b-col>
                    <b-col
                      v-if="leistung.Teile_IO"
                      cols="12"
                      md="4"
                      class="mt-1"
                    >
                      <p class="mb-0">
                        <b>Teile I.O.: </b>
                        {{ leistung.Teile_IO }}
                      </p>
                    </b-col>
                    <b-col
                      v-if="leistung.Teile_NIO"
                      cols="12"
                      md="8"
                      class="mt-1"
                    >
                      <p class="mb-0">
                        <b>Teile N.I.O.: </b>
                        {{ leistung.Teile_NIO }}
                      </p>
                    </b-col>
                    <b-col
                      v-if="leistung.Teile_Nachgearbeitet_IO"
                      cols="12"
                      md="4"
                      class="mt-1"
                    >
                      <p class="mb-0">
                        <b>Teile nachgearbeitet I.O.: </b>
                        {{ leistung.Teile_Nachgearbeitet_IO }}
                      </p>
                    </b-col>
                    <b-col
                      v-if="leistung.Teile_Nachgearbeitet_NIO"
                      cols="12"
                      md="8"
                      class="mt-1"
                    >
                      <p class="mb-0">
                        <b>Teile nachgearbeitet N.I.O.: </b>
                        {{ leistung.Teile_Nachgearbeitet_NIO }}
                      </p>
                    </b-col>
                    <b-col
                      v-if="leistung.Abweichendes_Fehlerbild"
                      cols="12"
                      md="12"
                      class="mt-1"
                    >
                      <p class="mb-0">
                        <b>Abweichendes Fehlerbild: </b>
                        {{ leistung.Abweichendes_Fehlerbild }}
                      </p>
                    </b-col>
                    <b-col
                      v-if="leistung.Taetigkeitsmerkmale"
                      cols="12"
                      md="12"
                      class="mt-1"
                    >
                      <p class="mb-0">
                        <b>Tätigkeitsmerkmale: </b>
                        {{ leistung.Taetigkeitsmerkmale }}
                      </p>
                    </b-col>
                    <b-col
                      v-if="leistung.Sonstiges"
                      cols="12"
                      md="12"
                      class="mt-1"
                    >
                      <p class="mb-0">
                        <b>Sonstiges: </b>
                        {{ leistung.Sonstiges }}
                      </p>
                    </b-col>
                    <b-col
                      v-if="$can('read', 'Admin')"
                      cols="12"
                      class="text-center"
                    >
                      <hr />
                      <b-button
                        variant="primary"
                        v-b-modal:[`modal-update-employee-service-${leistung.id}`]
                        >Mitarbeiterleistung bearbeiten</b-button
                      >
                      <update-employee-service-modal
                        :pruefpositionID="activePruefposition"
                        :leistung="leistung"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="py-1"
            v-if="
              pruefposition.Status !== 'Beendet' &&
              pruefposition.Status !== 'Abgebrochen'
            "
          >
            <validation-observer
              ref="registerPerformanceForm"
              #default="{ invalid }"
            >
              <b-form @submit.prevent="register">
                <b-row class="align-items-center justify-content-center">
                  <b-col cols="12" class="mb-2">
                    <h3>Tagesleistung</h3>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group
                      label="Arbeitsbeginn"
                      label-for="mitarbeiterleistung-arbeitsbeginn"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Arbeitsbeginn"
                        vid="arbeitsbeginn"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="tagesleistung.Arbeitsbeginn"
                          id="mitarbeiterleistung-arbeitsbeginn"
                          class="form-control"
                          :config="flatPickrConfig"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group
                      label="Pause"
                      label-for="mitarbeiterleistung-startzeit"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Pause"
                        vid="pause"
                        rules="required"
                      >
                        <b-input-group append="Minuten">
                          <b-form-input
                            v-model.number="tagesleistung.unformattedPause"
                            id="mitarbeiterleistung-pause"
                            class="form-control"
                            type="number"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group
                      label="Arbeitsende"
                      label-for="mitarbeiterleistung-arbeitsende"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Arbeitsende"
                        vid="arbeitsende"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="tagesleistung.Arbeitsende"
                          id="mitarbeiterleistung-arbeitsende"
                          class="form-control"
                          :config="flatPickrConfig"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" class="py-1">
                    <label>Nachtschicht</label>
                    <b-form-checkbox
                      id="mitarbeiterleistung-nachtschicht"
                      class="d-inline-block ml-50 mr-2"
                      v-model="nachtschicht"
                    />
                    <label> Feiertag</label>
                    <b-form-checkbox
                      id="mitarbeiterleistung-feiertag"
                      class="d-inline-block ml-50"
                      v-model="tagesleistung.Feiertag"
                    />
                  </b-col>
                  <b-col cols="12" class="py-1">
                    <label class="w-100">Geprüfte Teile</label>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <b-card
                        bg-variant="success"
                        class="mx-50 mb-0 details-card"
                      >
                        <b-form-group
                          label-class="text-white"
                          label="I.O."
                          label-for="mitarbeiterleistung-io"
                        >
                          <b-form-input
                            v-model.number="tagesleistung.Teile_IO"
                            id="mitarbeiterleistung-io"
                            type="number"
                          />
                        </b-form-group>
                      </b-card>
                      <b-card
                        bg-variant="danger"
                        class="mx-50 mb-0 details-card"
                      >
                        <b-form-group
                          label-class="text-white"
                          label="N.I.O."
                          label-for="mitarbeiterleistung-nio"
                        >
                          <b-form-input
                            v-model.number="tagesleistung.Teile_NIO"
                            id="mitarbeiterleistung-nio"
                            type="number"
                          />
                        </b-form-group>
                      </b-card>
                      <b-card
                        bg-variant="success"
                        class="mx-50 mb-0 details-card"
                      >
                        <b-form-group
                          label-class="text-white"
                          label="Nacharbeitet I.O."
                          label-for="mitarbeiterleistung-nachgearbeitet-io"
                        >
                          <b-form-input
                            v-model.number="
                              tagesleistung.Teile_Nachgearbeitet_IO
                            "
                            id="mitarbeiterleistung-nachgearbeitet-io"
                            type="number"
                          />
                        </b-form-group>
                      </b-card>
                      <b-card
                        bg-variant="danger"
                        class="mx-50 mb-0 details-card"
                      >
                        <b-form-group
                          label-class="text-white"
                          label="Nacharbeitet N.I.O."
                          label-for="mitarbeiterleistung-nachgearbeitet-nio"
                        >
                          <b-form-input
                            v-model.number="
                              tagesleistung.Teile_Nachgearbeitet_NIO
                            "
                            id="mitarbeiterleistung-nachgearbeitet-nio"
                            type="number"
                          />
                        </b-form-group>
                      </b-card>
                    </div>
                  </b-col>
                  <b-col cols="12" class="py-1 d-flex justify-content-center">
                    <b-card bg-variant="primary" class="mb-0 details-card">
                      <b-form-group
                        label-class="text-white"
                        label="Geprüft gesamt"
                        label-for="mitarbeiterleistung-teile-gesamt"
                      >
                        <b-form-input
                          v-model="tagesleistung.Teile_Gesamt"
                          id="mitarbeiterleistung-teile-gesamt"
                          type="number"
                          disabled
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col cols="12" class="py-1">
                    <label> <h3>Tätigkeit ausgeführt</h3></label>
                    <b-form-checkbox
                      id="mitarbeiterleistung-taetigkeit-ausgefuehrt"
                      class="d-inline-block ml-1"
                      v-model="tagesleistung.Taetigkeit_Ausgefuehrt"
                    />
                  </b-col>
                  <b-col cols="12" class="py-1">
                    <b-form-group
                      label="Abweichendes Fehlerbild"
                      label-for="mitarbeiterleistung-abweichendes-fehlerbild"
                    >
                      <b-form-textarea
                        v-model="tagesleistung.Abweichendes_Fehlerbild"
                        id="mitarbeiterleistung-abweichendes-fehlerbild"
                        placeholder="Bei abweichendem Fehlerbild, bitte kurz beschreiben"
                        rows="3"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" class="py-1">
                    <b-form-group
                      label="Tätigkeitsmerkmale (Prüftätigkeit)"
                      label-for="mitarbeiterleistung-taetigkeitsmerkmale"
                    >
                      <b-form-textarea
                        v-model="tagesleistung.Taetigkeitsmerkmale"
                        id="mitarbeiterleistung-taetigkeitsmerkmale"
                        placeholder="Kurze Beschreibung der Tätigkeit"
                        rows="3"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" class="py-1">
                    <b-form-group
                      label="Besonderheiten/Sonstiges"
                      label-for="mitarbeiterleistung-sonstiges"
                    >
                      <b-form-textarea
                        v-model="tagesleistung.Sonstiges"
                        id="mitarbeiterleistung-sonstiges"
                        placeholder="Kurze Beschreibung von Besonderheiten (z.B. Stillstandzeiten, abweichende Prüfkriterien, etc.)"
                        rows="3"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" class="text-right">
                    <b-button
                      variant="primary"
                      @click="postTagesleistung(pruefpositionIndex)"
                      >Tagesleistung speichern</b-button
                    >
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="py-1"
            v-if="
              pruefposition.Status === 'Beendet' ||
              pruefposition.Status === 'Abgebrochen'
            "
          >
            <h3>
              Die Prüfposition wurde
              {{ pruefposition.Status.toLowerCase() }} und kann nicht weiter
              bearbeitet werden.
            </h3>
          </b-col>
        </b-row>
      </b-card>
      <b-button
        v-if="$can('read', 'Mitarbeiter')"
        class="mb-2"
        variant="primary"
        v-b-modal.modal-add-control-position
        >Prüfposition hinzufügen</b-button
      >
      <add-control-position-modal
        :orderData="{ id: orderData.id, number: orderData.Auftragsnummer }"
        :employeeData="{
          id: currentEmployeeID,
          name: employeeName,
        }"
        @newControlPositionAdded="getOrderData"
      />
    </div>
  </component>
</template>

<script>
import {
  BCard,
  BAlert,
  BLink,
  BRow,
  BCol,
  BButton,
  BForm,
  BImgLazy,
  BAspect,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
  VBModal,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { German } from 'flatpickr/dist/l10n/de';
import AddControlPositionModal from '@/layouts/components/modals/AddControlPositionModal.vue';
import UpdateEmployeeServiceModal from '@/layouts/components/modals/UpdateEmployeeServiceModal.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { required } from '@validations';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import de from 'vee-validate/dist/locale/de.json';
import VueEasyLightbox from 'vue-easy-lightbox';

localize('de', de);

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BButton,
    BForm,
    BImgLazy,
    BAspect,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormTextarea,
    BBadge,
    flatPickr,
    VBModal,
    AddControlPositionModal,
    UpdateEmployeeServiceModal,
    ValidationProvider,
    ValidationObserver,
    VueEasyLightbox,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      orderData: {},
      activePruefposition: 0,
      activeLeistung: 0,
      nachtschicht: false,
      currentEmployeeID: null,
      tagesleistung: {
        Arbeitsbeginn: null,
        unformattedPause: 0,
        Pause: null,
        Arbeitsende: null,
        Arbeitszeit: null,
        Arbeitszeit_Nachtschicht: null,
        Arbeitszeit_Samstag: null,
        Arbeitszeit_Wochenende: null,
        Feiertag: false,
        Teile_IO: null,
        Teile_NIO: null,
        Teile_Nachgearbeitet_IO: null,
        Teile_Nachgearbeitet_NIO: null,
        Teile_Gesamt: null,
        Abweichendes_Fehlerbild: null,
        Taetigkeitsmerkmale: null,
        Sonstiges: null,
        Taetigkeit_Ausgefuehrt: false,
        Mitarbeiter: null,
      },
      flatPickrConfig: {
        enableTime: true,
        dateFormat: 'Z',
        altInput: true,
        altFormat: 'd.m.Y, H:i \\U\\h\\r',
        allowInput: true,
        time_24hr: true,
        defaultHour: 7,
        locale: German,
      },
      status: [
        {
          1: 'Bestätigt',
          2: 'Bereit',
          3: 'Laufend',
          4: 'Beendet',
          5: 'Unterbrochen',
          6: 'Abgebrochen',
        },
        {
          1: 'info',
          2: 'success',
          3: 'dark',
          4: 'primary',
          5: 'warning',
          6: 'danger',
        },
      ],
      pruefpositionenLightbox: [],
    };
  },
  computed: {
    employeeServices() {
      return this.orderData.Pruefpositionen.reduce(
        (orderItem, pruefposition) => {
          const employeeServices = [];
          pruefposition.Mitarbeiterleistungen.forEach(mitarbeiterleistung => {
            if (
              mitarbeiterleistung.Mitarbeiter &&
              mitarbeiterleistung.Mitarbeiter.id == this.currentEmployeeID
            ) {
              employeeServices.push(mitarbeiterleistung);
            }
          });
          orderItem[pruefposition.id] = employeeServices;
          return orderItem;
        },
        {}
      );
    },
    employeeName() {
      return JSON.parse(localStorage.getItem('userData')).mitarbeiterName;
    },
    ansprechpartner() {
      return this.orderData.Kunde.Ansprechpartner.filter(
        ansprechpartner =>
          ansprechpartner.id === this.orderData.Ansprechpartner_ID
      )[0];
    },
    partsSum() {
      return (
        +this.tagesleistung.Teile_IO +
        +this.tagesleistung.Teile_NIO +
        +this.tagesleistung.Teile_Nachgearbeitet_IO +
        +this.tagesleistung.Teile_Nachgearbeitet_NIO
      );
    },
    partDifferences() {
      return this.orderData.Pruefpositionen.reduce(
        (orderItem, pruefposition) => {
          let finishedParts = 0;
          pruefposition.Mitarbeiterleistungen.forEach(mitarbeiterleistung => {
            finishedParts += mitarbeiterleistung.Teile_Gesamt;
          });
          orderItem[pruefposition.id] = {
            finishedParts: finishedParts,
            text: `${finishedParts} ${
              pruefposition.Stueckzahl
                ? `von ${pruefposition.Stueckzahl} Teilen grprüft`
                : 'Teile geprüft'
            }`,
            icon: finishedParts >= pruefposition.Stueckzahl,
          };
          return orderItem;
        },
        {}
      );
    },
  },
  watch: {
    partsSum() {
      // Set total checked Parts
      this.tagesleistung.Teile_Gesamt = this.partsSum;
    },
  },
  beforeRouteEnter(to, from, next) {
    // Initialize Page Title
    to.meta.pageTitle = `Auftrag abarbeiten`;
    next();
  },
  async created() {
    await this.getOrderData();
    this.currentEmployeeID =
      this.$route.params.mitarbeiterId ||
      JSON.parse(localStorage.getItem('userData')).mitarbeiterID;
    // Change Page Title
    this.$route.meta.pageTitle = `Auftrag #${this.orderData.Auftragsnummer} bearbeiten`;
    this.$router.replace({ query: { temp: Date.now() } });
    this.$router.replace({ query: { temp: undefined } });
  },
  methods: {
    showLightbox(pruefpositionIndex, imgIndex) {
      this.pruefpositionenLightbox[pruefpositionIndex].lightboxVisible = true;
      this.pruefpositionenLightbox[pruefpositionIndex].lightboxIndex = imgIndex;
    },
    hideLightbox(pruefpositionIndex) {
      this.pruefpositionenLightbox[pruefpositionIndex].lightboxVisible = false;
    },
    pruefpositionStatus(order) {
      switch (true) {
        case order === 'Bestaetigt':
          return 1;
        case order === 'Bereit':
          return 2;
        case order === 'Laufend':
          return 3;
        case order === 'Beendet':
          return 4;
        case order === 'Ruht':
          return 5;
        case order === 'Abgebrochen':
          return 6;
        default:
          return 1;
      }
    },
    initializeTagesleistung() {
      this.tagesleistung = {
        Arbeitsbeginn: null,
        unformattedPause: 0,
        Pause: null,
        Arbeitsende: null,
        Arbeitszeit: null,
        Arbeitszeit_Nachtschicht: null,
        Arbeitszeit_Samstag: null,
        Arbeitszeit_Wochenende: null,
        Feiertag: false,
        Teile_IO: null,
        Teile_NIO: null,
        Teile_Nachgearbeitet_IO: null,
        Teile_Nachgearbeitet_NIO: null,
        Teile_Gesamt: null,
        Abweichendes_Fehlerbild: null,
        Taetigkeitsmerkmale: null,
        Sonstiges: null,
        Taetigkeit_Ausgefuehrt: false,
        Mitarbeiter: null,
      };
    },
    async postTagesleistung(pruefpositionIndex) {
      this.$refs.registerPerformanceForm[0].validate().then(async isValid => {
        if (!isValid || this.orderIdNotUnique) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Eingabe nicht korrekt!`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `Die Eingabe ist nicht korrekt. Bitte überprüfen Sie alle Felder und probieren Sie es noch einmal.`,
            },
          });
          return;
        }

        const activePruefposition =
          this.orderData.Pruefpositionen[pruefpositionIndex];

        // Format Break to Minutes
        const breakMinutes = new Date(0);
        breakMinutes.setMinutes(+this.tagesleistung.unformattedPause);
        this.tagesleistung.Pause = breakMinutes.toISOString().substr(11, 8);

        // Calculate working time
        const arbeitsbeginn = new Date(this.tagesleistung.Arbeitsbeginn);
        const arbeitsende = new Date(this.tagesleistung.Arbeitsende);
        const arbeitszeit = new Date(
          arbeitsende - arbeitsbeginn - breakMinutes
        );
        this.tagesleistung.Arbeitszeit = arbeitszeit
          .toISOString()
          .substr(11, 8);

        if (this.nachtschicht) {
          this.tagesleistung.Arbeitszeit_Nachtschicht =
            this.tagesleistung.Arbeitszeit;
        }
        if (arbeitsbeginn.getDay() === 6) {
          this.tagesleistung.Arbeitszeit_Samstag =
            this.tagesleistung.Arbeitszeit;
        }
        if (arbeitsbeginn.getDay() === 0 || this.tagesleistung.Feiertag) {
          this.tagesleistung.Arbeitszeit_Wochenende =
            this.tagesleistung.Arbeitszeit;
        }

        // Set Prüfposition Status
        switch (true) {
          case activePruefposition.Mitarbeiterleistungen.length === 0:
            activePruefposition.Status = 'Laufend';
            break;
          case activePruefposition.Stueckzahl > 300 &&
            this.partsSum +
              this.partDifferences[activePruefposition.id].finishedParts >=
              +activePruefposition.Stueckzahl:
            activePruefposition.Status = 'Beendet';
            break;
          default:
            break;
        }

        // Set Employee ID
        this.tagesleistung.Mitarbeiter = this.currentEmployeeID;

        // Push Tagesleistung to Tagesleistungen
        activePruefposition.Mitarbeiterleistungen.push(this.tagesleistung);

        // Set Employees on Pruefposition
        if (
          !activePruefposition.Mitarbeiter.find(
            mitarbeiter => mitarbeiter.id === this.currentEmployeeID
          )
        ) {
          activePruefposition.Mitarbeiter.push({
            id: this.currentEmployeeID,
          });
        }

        await this.$http
          .put(`/pruefpositionen/${activePruefposition.id}`, {
            data: {
              Mitarbeiterleistungen: activePruefposition.Mitarbeiterleistungen,
              Mitarbeiter: activePruefposition.Mitarbeiter,
              Status: activePruefposition.Status,
            },
          })
          .then(async () => {
            this.initializeTagesleistung();
            await this.getOrderData();

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Tagsesleistung angelegt`,
                icon: 'SaveIcon',
                variant: 'success',
                text: `Die Tagesleistung wurde erfolgreich gespeichert.`,
              },
            });
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Anlegen der Tagesleistung fehlgeschlagen!`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `Die Tagesleistung konnte nicht gespeichert werden. Bitte überprüfen Sie die Eingabe und probieren Sie es noch einmal.`,
              },
            });
            console.log(err.response.data.error.message);
          });
      });
    },
    setActivePruefposition(id) {
      if (this.activePruefposition === id) {
        this.activePruefposition = 0;
      } else {
        this.activePruefposition = id;
      }
    },
    setActiveLeistung(id, event) {
      // Prevent closing the tile if clicking on the button
      if (event.target.classList[0] === 'btn') {
        return;
      }
      // Switch active tile
      if (this.activeLeistung === id) {
        this.activeLeistung = 0;
      } else {
        this.activeLeistung = id;
      }
    },
    async getOrderData() {
      const qs = require('qs');
      const query = qs.stringify(
        {
          fields: [
            'Ansprechpartner_ID',
            'Auftrag_Bei_Muster',
            'Auftrag_Bei_Pruefanweisung',
            'Auftrag_Bei_Sonstige',
            'Auftragsnummer',
          ],
          populate: {
            Abweichender_Auftragsort: {
              populate: '*',
            },
            Kunde: {
              fields: ['Firma'],
              populate: {
                Anschrift: {
                  fields: '*',
                },
                Ansprechpartner: {
                  fields: '*',
                },
              },
            },
            Pruefpositionen: {
              fields: '*',
              sort: ['id:asc'],
              populate: {
                Taetigkeit: {
                  fields: '*',
                },
                Mitarbeiter: {
                  fields: ['id'],
                },
                Mitarbeiterleistungen: {
                  fields: '*',
                  populate: {
                    Mitarbeiter: {
                      fields: 'id',
                    },
                  },
                },
                Anhaenge: {
                  fields: ['url', 'formats'],
                },
              },
            },
          },
        },
        {
          encodeValuesOnly: true,
          indices: false,
        }
      );
      await this.$http
        .get(`/auftraege/${this.$route.params.id}?${query}`)
        .then(response => {
          this.orderData = response.data.data;
          this.orderData.Pruefpositionen.forEach((pruefposition, index) => {
            this.pruefpositionenLightbox.push({
              imgUrls: [],
              imgThumbnailUrls: [],
              lightboxIndex: 0,
              lightboxVisible: false,
            });
            if (pruefposition.Anhaenge != null) {
              pruefposition.Anhaenge.forEach(anhang => {
                this.pruefpositionenLightbox[index].imgUrls.push(
                  `${this.$dbBaseUrl}${anhang.url}`
                );
                this.pruefpositionenLightbox[index].imgThumbnailUrls.push(
                  `${this.$dbBaseUrl}${anhang.formats.thumbnail.url}`
                );
              });
            }
          });
        })
        .catch(err => console.log(err));
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
.google-map {
  border-radius: 0.5rem;

  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
.toggle-pruefposition {
  top: 2.5rem;
  right: 2rem;
}

.b-form-spinbutton.form-control .btn-sm {
  color: white;
  background-color: red;
}
.card.details-card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}

.single-leistung:hover {
  transform: scale(1.02);
}

.b-aspect {
  background-color: white;
  transition: all 0.25s ease-in;
  cursor: pointer;
}
.b-aspect:hover {
  transform: scale(1.05);
}
.detail-image {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
</style>
